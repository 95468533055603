
	import { Component, Vue, Prop } from 'vue-property-decorator';
	@Component({
		name: 'HRendererScroll',
		components: {},
		computed: {},
	})
	export default class HRendererScroll extends Vue {
		@Prop() activeClassName: string;
		@Prop() withArrows: boolean;
		@Prop() disableWheel: boolean;
		@Prop() disableTouchMovement: boolean;
		@Prop({ default: 105 }) movement: number;

		$refs: {
			refScrollRenderer: HTMLElement;
			refScrollRendererContainer: HTMLElement;
		};

		translateX = 0;
		_targetSliderWidth;
		_touchStart = false;
		_lastTouchPosition: Touch | null = null;
		_sliderWidth = 0;
		showLeftArrow = false;
		showRightArrow = false;

		// touch events
		_handleTouchStart;
		_handleTouchEnd;
		_handleTouchMove;

		// mouse events
		_handleMouseMove;
		_handleMouseUp;

		//
		_handleWindowResize;
		_eventPrevented;
		_canWrappRenderer;

		// scroll controle

		handleScroll(e: WheelEvent) {
			if (this.disableWheel && !e.shiftKey) return;
			e.preventDefault();
			e.stopPropagation();
			const canWrappHeader = this._canWrappRenderer;
			if (canWrappHeader) {
				this.updateSliderScroll(-e.deltaY);
			}
		}

		// touch controle

		handleTouchStart() {
			if (this.disableTouchMovement) return;
			const canWrappHeader = this._canWrappRenderer;
			if (canWrappHeader) {
				this._touchStart = true;
				document.addEventListener('touchmove', this._handleTouchMove);
				document.addEventListener('touchend', this._handleTouchEnd);
			}
		}

		handleTouchMove(e: TouchEvent) {
			if (!this._touchStart) return;
			const touch: Touch = e.touches[0];
			if (this._lastTouchPosition) {
				const movementX = touch.pageX - this._lastTouchPosition.pageX;
				this.updateSliderScroll(movementX);
			}
			this._lastTouchPosition = touch;
		}

		handleTouchEnd() {
			if (!this._touchStart) return;
			this._lastTouchPosition = null;
			document.removeEventListener('touchmove', this._handleTouchMove);
			document.removeEventListener('touchend', this._handleTouchEnd);
		}

		// mouse controle

		handleMouseDown() {
			if (this.disableTouchMovement) return;
			const canWrappHeader = this._canWrappRenderer;
			if (canWrappHeader) {
				document.addEventListener('mousemove', this._handleMouseMove);
				document.addEventListener('mouseup', this._handleMouseUp);
			}
		}

		handleMouseMove(e: MouseEvent) {
			const movementX = e.movementX;
			if (!this._eventPrevented) {
				this._eventPrevented = true;
				this.$refs.refScrollRenderer.style.pointerEvents = 'none';
			}
			this.updateSliderScroll(movementX);
		}

		handleMouseUp() {
			if (this._eventPrevented) {
				this._eventPrevented = null;
				this.$refs.refScrollRenderer.style.pointerEvents = '';
			}
			document.removeEventListener('mousemove', this._handleMouseMove);
			document.removeEventListener('mouseup', this._handleMouseUp);
		}

		updateSliderScroll(movement: number) {
			if (!this._targetSliderWidth) return;
			this.translateX = this.translateX + movement;
			this._transformChip();
		}

		handleWindowResize() {
			this._initWrapInfo();
			this.translateX = 0;
			this._transformChip();
		}

		_initWrapInfo() {
			const targetSliderWidth = this.$refs.refScrollRenderer.clientWidth;
			const viewWidth = this.$refs.refScrollRendererContainer.clientWidth;
			const canWrappHeader = viewWidth < targetSliderWidth;
			if (canWrappHeader) {
				this._targetSliderWidth = targetSliderWidth - viewWidth;
			}
			this._canWrappRenderer = canWrappHeader;
		}

		_emitControlArrowSingals() {
			if (!this.withArrows) return;
			if (this._canWrappRenderer) {
				const offset = 5;
				if (this.translateX + offset >= 0) this.showLeftArrow = false;
				else this.showLeftArrow = true;
				if (this.translateX - offset <= -this._targetSliderWidth) this.showRightArrow = false;
				else this.showRightArrow = true;
			} else {
				this.showLeftArrow = false;
				this.showRightArrow = false;
			}
		}

		_transformChip() {
			const translate = this.translateX;
			if (translate > 0) this.translateX = 0;
			else if (translate < -this._targetSliderWidth) this.translateX = -this._targetSliderWidth;
			this._emitControlArrowSingals();
		}

		initTranslateX() {
			const activeClassName = this.activeClassName;
			if (activeClassName && this._canWrappRenderer) {
				setTimeout(() => {
					if (!this.$refs.refScrollRenderer) return;
					const $activeElem = this.$refs.refScrollRenderer.querySelector('.' + activeClassName) as HTMLElement;
					const offsetLeft = $activeElem?.offsetLeft;
					const targetSliderWidth = this.$refs.refScrollRendererContainer.clientWidth;
					if (offsetLeft >= targetSliderWidth) {
						this.translateX = -offsetLeft;
						this._transformChip();
					}
				}, 100);
			}
		}

		beforeMount() {
			this._handleTouchStart = this.handleTouchStart.bind(this);
			this._handleTouchEnd = this.handleTouchEnd.bind(this);
			this._handleTouchMove = this.handleTouchMove.bind(this);
			this._handleMouseMove = this.handleMouseMove.bind(this);
			this._handleMouseUp = this.handleMouseUp.bind(this);
			this._handleWindowResize = this.handleWindowResize.bind(this);
		}

		mounted() {
			this._initWrapInfo();
			this.initTranslateX();
			this._emitControlArrowSingals();
			const headerSlider = this.$refs.refScrollRenderer;
			headerSlider.addEventListener('touchstart', this._handleTouchStart);
			window.addEventListener('resize', this._handleWindowResize);
		}

		updated() {
			if (this.$refs.refScrollRenderer && this.$refs.refScrollRenderer.clientWidth !== this._sliderWidth) {
				this.$nextTick(() => {
					this._sliderWidth = this.$refs.refScrollRenderer.clientWidth;
					this._initWrapInfo();
					this._emitControlArrowSingals();
				});
			}
		}
		beforeDestroy() {
			const headerSlider = this.$refs.refScrollRenderer;
			headerSlider.removeEventListener('touchstart', this._handleTouchStart);
			window.removeEventListener('resize', this._handleWindowResize);
		}
	}
