
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	@Component({
		name: 'EditableTextarea',
		components: {
			BodyText,
		},
	})
	export default class EditableTextarea extends Vue {
		@Prop() label: string;
		@Prop() defaultValue: string;
		@Prop() placeholder: string;
		@Prop({ default: false }) enterWithShift: boolean;
		@Prop({ default: '130px' }) minHeight: string;

		$refs: {
			inputTextArea: HTMLElement;
		};
		isEmptyMessage = true;

		@Watch('defaultValue')
		onDefaultValueChange() {
			this.$nextTick(() => {
				this.handlePlaceholder();
			});
		}

		handleChange() {
			this.handlePlaceholder();
			this.$emit('input', this.$refs.inputTextArea.innerHTML, this.$refs.inputTextArea.innerText.trim());
		}

		handlePaste(e: ClipboardEvent) {
			e.preventDefault();
			e.stopPropagation();
			const pastedText = e.clipboardData?.getData('Text');
			const selection = window.getSelection();
			if (pastedText && selection) {
				const range = selection.getRangeAt(0);
				range.deleteContents();
				range.insertNode(document.createTextNode(pastedText));
				range.collapse(false);
				selection.removeAllRanges();
				selection.addRange(range);
				this.handleChange();
			}
		}

		handlePlaceholder() {
			const innerText = this.$refs.inputTextArea.innerText.trim();
			if (this.isEmptyMessage && innerText.length > 0) this.isEmptyMessage = false;
			else if (innerText.length === 0 && !this.isEmptyMessage) this.isEmptyMessage = true;
		}
		handleEnterDown(e: KeyboardEvent) {
			if (!this.enterWithShift) return;
			if (!e.shiftKey) {
				e.preventDefault();
				this.$emit('enter', this.$refs.inputTextArea.innerHTML, this.$refs.inputTextArea.innerText);
			}
		}

		mounted() {
			this.handlePlaceholder();
		}
	}
