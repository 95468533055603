import { render, staticRenderFns } from "./IconLYoutube.vue?vue&type=template&id=6595f96a&"
import script from "./IconLYoutube.vue?vue&type=script&lang=ts&"
export * from "./IconLYoutube.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports